.Toastify__toast {
  min-height: 45px !important;
}

.compliment-toast-container > div {
  margin-bottom: 10px;
  box-shadow: 0 5px 45px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.Toastify__toast--default .compliment-progress {
  background: #7ed3ba !important;
  height: 3px !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.Toastify__toast--error .compliment-progress {
  background: #842029 !important;
  height: 3px !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.Toastify__toast--info .compliment-progress {
  background: #084298 !important;
  height: 3px !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.Toastify__toast--success .compliment-progress {
  background: #0f5132 !important;
  height: 3px !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.Toastify__toast--warning .compliment-progress {
  background: #664d03 !important;
  height: 3px !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.compliment-body {
  margin: 0 !important;
}

.Toastify__toast--error .compliment-body {
  color: #842029;
}

.Toastify__toast--info .compliment-body {
  color: #084298;
}

.Toastify__toast--success .compliment-body {
  color: #0f5132;
}

.Toastify__toast--warning .compliment-body {
  color: #664d03;
}

.Toastify__toast--default .compliment-body {
  color: black;
}

.Toastify__toast--error {
  background: #f8d7da !important;
}

.Toastify__toast--info {
  background: #cfe2ff !important;
}

.Toastify__toast--success {
  background: #d1e7dd !important;
}

.Toastify__toast--warning {
  background: #fff3cd !important;
}

.Toastify__toast--default {
  background: black;
}
